<template>
  <div class="flex items-center justify-center h-screen bg-gray-300">
		<!-- Container -->
		<div class="container mx-auto">
			<div class="flex justify-center px-6 my-12">
				<!-- Row -->
				<div class="w-full xl:w-3/5 lg:w-11/12 flex shadow-xl border-1 border-gray-400">
					<!-- Col -->
					<div
            id="animated"
						class="w-full h-auto bg-gray-400 hidden lg:block lg:w-1/2 bg-cover rounded-l-lg"
					>
          </div>
					<!-- Col -->
					<div class="w-full lg:w-1/2  p-5  lg:rounded-l-none rounded-r-lg" style="background-color: rgba(255, 255, 255,1)">
						<div class="px-8 text-center">
              <h4 class="font-semibold mt-5 mb-5 pb-1 text-3xl">Register</h4>
						</div>
            <form>
              <!-- BEGIN: Email --->
              <div>
                <v-text-field placeholder="Email"
                  v-model="email"  
                  required outlined 
                  dense
                />
              </div>
              <!-- END: Email --->

              <!-- BEGIN: Password --->
              <div>
                <v-text-field v-model="password1"  
                  :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'" 
                  :rules="[passwordRules]"
                  :type="show ? 'text' : 'password'"
                  class="input-group--focused"
                  outlined placeholder="Enter password" 
                  @click:append="show = !show" 
                  dense
                />
              </div>
              <!-- END: Password --->

              <!-- BEGIN: Password 2 --->
              <div>
                <v-text-field v-model="password2"  
                  :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'" 
                  :rules="[passwordRules]"
                  :type="show1 ? 'text' : 'password'"
                  class="input-group--focused" dense 
                  outlined placeholder="Enter password" 
                  @click:append="show1 = !show1" 
                />
              </div>
              <!-- END: Password 2--->
          

              <div class="text-center pt-1 mb-1 pb-1">
                <v-btn block color="primary" depressed @click="register()">
                  REGISTER
                </v-btn>
              </div>
              <div class="flex items-center justify-between pb-2">
                <span class="text-sm ml-2 hover:text-blue-500 cursor-pointer" @click="goToLogin()">Have an account?</span>
                <span class="text-sm ml-2 hover:text-blue-500 cursor-pointer" @click="goToHome()">Back home</span>
              </div>
            </form>
          </div>
				</div>
			</div>
		</div>
  </div>
</template>
<script>
import { notifyError } from '../../plugins/notification.service';
export default {
  data: () => ({
    show: false,
    show1: false,
    username: "",
    password1: "",
    password2: "",
    email: "",
    nameRules: [(v) => !!v || "This field is required"],
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
    ],
    passwordRules: [
      (v) => !!v || "This field is required",
      (v) => (v && v.length >= 8) || "Name must not be less than 8 characters",
    ],
  }),
  methods: {
    register() {
        if (this.email) {
          if (this.password1) {
            if (this.password1 == this.password2) {
              this.$store.dispatch("userRegister", {
                username: this.email,
                email: this.email,
                password1: this.password1,
                password2: this.password2
              });

            } else {
              notifyError("passwords must match")
            }
          } 
          else {
              notifyError("please provide a password")
          }
        } 
        else {
              notifyError("please provide an email")
        }
    },
    goToLogin(){
      this.$router.push({ name: "Login" })
    },

    goToHome(){
      this.$router.push({ name: "Landing" })
    }
  },
};
</script>

<style scoped>
#animated {
  background: url("../../assets/images/Kilimanjaro.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}
</style>