import { render, staticRenderFns } from "./RegisterPage.vue?vue&type=template&id=f17e2fee&scoped=true&"
import script from "./RegisterPage.vue?vue&type=script&lang=js&"
export * from "./RegisterPage.vue?vue&type=script&lang=js&"
import style0 from "./RegisterPage.vue?vue&type=style&index=0&id=f17e2fee&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f17e2fee",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VBtn,VTextField})
